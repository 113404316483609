import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: '',
      component: () => import('./views/Index.vue')
    },
    {
      path: '/llms.txt',
      name: 'llmsTxt',
      /**
       * Navigation guard that redirects to the static llms.txt file located in the public folder.
       * @param {import('vue-router').Route} to - The target route.
       * @param {import('vue-router').Route} from - The current route.
       * @param {import('vue-router').NavigationGuardNext} next - Callback to resolve the navigation.
       */
      beforeEnter: (to, from, next) => {
        window.location.href = `${process.env.BASE_URL}llms.txt`
      }
    }
  ]
})
